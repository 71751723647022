<template>
  <div class="flex flex-col space-y-2">
    <div class="flex space-x-1">
      <div
        :class="['tab', { 'active': selectedTab === 'PUBLIC'}]"
        @click="changeTab('PUBLIC')"
      >Umum</div>
      <div
        :class="['tab', { 'active': selectedTab === 'TEEN'}]"
        @click="changeTab('TEEN')"
      >Remaja & Pemuda</div>
      <div
        :class="['tab', { 'active': selectedTab === 'KIDS'}]"
        @click="changeTab('KIDS')"
      >Anak</div>
    </div>
    <div class="flex w-full flex-col space-y-2">
      <ScheduleCard
        v-for="event in selectedEvent"
        :key="event.id"
        :event="event"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { defineAsyncComponent } from 'vue'
const ScheduleCard = defineAsyncComponent(() => import('@/components/schedule/ScheduleCard.vue'))
export default {
  name: 'Schedule',
  components: {
    ScheduleCard
  },
  data () {
    return {
      selectedTab: 'PUBLIC'
    }
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapState('event', ['events']),
    selectedEvent () {
      return this.events.filter(event => event.type === this.selectedTab)
    }
  },
  methods: {
    ...mapActions('event', ['fetch']),
    initPage () {
      this.fetch()
    },
    changeTab (value) {
      this.selectedTab = value
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  @apply flex w-2/6 h-20 justify-center items-center text-base
  bg-gray-200 text-blue-600 text-center px-6 rounded-t-2xl cursor-pointer;
}
.active {
  @apply bg-blue-500 text-white;
}
</style>
